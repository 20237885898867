const getUniversityDistanceText = (universityDistances: any[]): string => {
	if (!universityDistances || !universityDistances.length) {
		return '';
	}

	const minKmPerUniversity = universityDistances.map(university => {
		const { name, distances } = university;

		const kmArray = distances.map(({ distance }: any) =>
			typeof distance === 'string' ? +distance.split(' ')[0] : distance,
		);
		const minKm = Math.min(...kmArray);

		return { name, km: minKm };
	});

	const closestUniversity = minKmPerUniversity.reduce(
		(acc, val) => (acc.km < val.km ? acc : val),
		minKmPerUniversity[0],
	);
	const { km, name } = closestUniversity;

	return `${km}km away from ${name}`;
};

export const universityDistanceService = {
	getUniversityDistanceText,
};
