import styled from 'styled-components';

import { Title } from 'lib/Title';

import theme from 'config/theme';
import { Link } from 'react-router-dom';
import { BREAKPOINT_TABLET_PX, BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { fontWeights, fontSizes, colors } = theme;

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
	max-width: 1200px;
	margin: 0 auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		max-width: 100%;
	}
`;
export const Divider = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid ${colors.grey10};
	width: 100%;
`;

export const BulletedList = styled.ol`
	margin-left: 16px;
	list-style: disc;
	margin-bottom: 24px;
`;

export const BulletedListItem = styled.li`
	list-style: disc;
	margin-left: 16px;
	line-height: 20px;
	margin-bottom: 16px;
`;
export const AboutImageContainer = styled.div`
	display: flex;

	img {
		flex: 1;
		width: 40px;
	}
`;

export const SectionTitle = styled(Title)`
	margin-bottom: 20px;
	color: rgba(${colors.darkBlue}, 0.5);
`;

export const LinkText = styled.a`
	color: ${colors.linkBlue};
	font-size: ${fontSizes.medium};
`;

export const SafetyTitle = styled.h2`
	margin-bottom: 20px;
	color: ${colors.darkBlue};
	font-size: 40px;
	font-weight: 700;
	line-height: 110%;
`;

export const BoldBlueContentText = styled.p`
	font-size: 20px;
	margin-bottom: 20px;
	line-height: 110%;
	font-weight: ${fontWeights.bold};
	color: ${colors.darkBlue};
`;

export const BlueInfoText = styled.p`
	font-size: 16px;
	margin-bottom: 20px;
	line-height: 150%;
	font-weight: ${fontWeights.normal};
	color: ${colors.darkBlue};
`;

export const BlueContentText = styled.p`
	font-size: 20px;
	margin-bottom: 20px;
	line-height: 110%;
	font-weight: ${fontWeights.normal};
	color: ${colors.darkBlue};
`;

export const ContentText = styled.p<{ indent?: boolean }>`
	font-size: ${fontSizes.medium};
	margin-bottom: 20px;
	margin-left: ${({ indent }) => (indent ? '24px' : '0')};
	font-weight: ${fontWeights.semi};
	color: ${colors.grey60};
`;

export const BoldText = styled.span<{ underline?: boolean }>`
	font-weight: ${fontWeights.bold};
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export const SectionSubTitle = styled.h3`
	font-size: 20px;
	color: ${colors.grey60};
	margin-bottom: 20px;
	font-weight: ${fontWeights.bold};
	span {
		margin-left: 10px;
	}
`;

export const StaticList = styled.ul<{ indent: number }>`
	margin-bottom: 24px;
	color: ${colors.grey60};
	li {
		list-style-type: disc;
		margin-left: ${({ indent }) => `${24 * indent}px`};
		margin-bottom: 5px;
	}
`;

export const OrderedList = styled.ol<{ indent: number; nested?: boolean }>`
	margin-bottom: 24px;
	color: ${colors.grey60};
	counter-reset: item;
	padding-top: 120px;
	margin-top: -120px;
	margin-left: 8px;

	li {
		display: block;
		margin-top: 8px;
		margin-left: ${({ indent }) => `${24 * indent}px`};
	}
	li:before {
		content: counters(item, '.') '. ';
		counter-increment: item;
		margin-left: -24px;
		width: 56px;
		display: inline-block;
	}

	a {
		position: relative;
		z-index: 10;
	}
`;

export const TableContainer = styled.div`
	width: 100%;
	overflow-x: auto;
`;

export const PrivacyTable = styled.table`
	border: 1px solid black;
	margin: 24px 0;

	td,
	th {
		border: 1px solid black;
		width: 25%;
		padding: 10px;
	}

	th {
		text-align: center;
		font-weight: ${fontWeights.bold};
		vertical-align: middle;
	}
	p {
		line-height: 16px;
		margin-bottom: 8px;
	}
`;

export const DetailsContainer = styled.div``;

export const SafetyGridContainer = styled.div`
	// margin: 20px auto 40px auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-gap: 165px;
	grid-row-gap: 24px;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		grid-template-columns: auto;
		gap: 32px;
	}

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		grid-template-columns: auto auto auto;
	}
`;

export const LogoGridContainer = styled.div`
	margin: 20px auto 40px auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-gap: 30px;
	max-width: 900px;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		grid-template-columns: auto auto auto;
	}

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		grid-template-columns: auto auto auto auto;
	}
`;

export const PartnerLink = styled.a`
	background-color: ${colors.gray};
	border-radius: 50%;
	overflow: hidden;
	padding: 30px;
	filter: grayscale(100%);
	transition: filter 0.7s;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		filter: grayscale(0%);
	}
`;

export const Spacer = styled.div<{ top?: boolean; large?: boolean }>`
  ${({ top }) => (top ? 'padding-top: ' : 'padding-bottom: ')}${({ large }) =>
	large ? '40px' : '20px'};
`;

export const LinkButton = styled(Link)`
	font-size: 14px;
	font-weight: 600;
	margin: 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	padding: 0 16px;
	height: 56px;
	user-select: none;
	outline: none;
	cursor: pointer;
	width: 100%;
	color: white;
	background: ${colors.darkBlue};
	position: relative;
	overflow: hidden;
	max-width: 300px;

	&:after {
		content: '';
		background: ${colors.white};
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		transition: all 400ms;
	}

	&:active:after {
		opacity: 0.4;
	}
`;
