export enum PropertyType {
	Unknown = '',
	House = 'house',
	Apartment = 'apartment',
	Flatlet = 'cottage',
	StudentResidence = 'residence',
}

export enum LeaseType {
	Unknown = '',
	EntirePlace = 'single',
	RoomByRoom = 'multi',
	Any = 'either',
}

export enum BathroomType {
	Unknown = '',
	Ensuite = 'private',
	Shared = 'shared',
}

export enum RentalPeriod {
	Unknown = '',
	Monthly = 'monthly',
	Weekly = 'weekly',
}

export interface ValidationResult {
	valid: boolean;
	error?: string;
}
