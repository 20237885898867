import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

const { colors } = theme;

const BarContainer = styled.div`
	width: 100%;
	min-height: 5px;
	display: flex;
	align-items: center;
	background: ${colors.gray};
`;

const BarFill = styled.div`
	height: 100%;
	background: ${colors.darkTurquoise};
	transition: width 400ms ease-in-out;
	min-height: 5px;
`;

interface ProgressBarProps {
	percentage: number;
}

const ProgressBar = ({ percentage = 0 }: ProgressBarProps) => (
	<BarContainer>
		<BarFill style={{ width: `${percentage}%` }} />
	</BarContainer>
);

export default ProgressBar;
