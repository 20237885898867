import React, { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as CaretUp } from 'assets/images/icons/caret-up.svg';
import { ReactComponent as CaretDown } from 'assets/images/icons/caret-down.svg';
import theme from 'config/theme';

const { colors } = theme;

interface CollapsibleSectionProps {
	header: string;
	children: any;
	expand?: boolean;
	onClick?(): void;
}

const expandToggleStyle = {
	height: '14px',
	width: '14px',
	minWidth: '14px',
	minHeight: '14px',
	marginLeft: '8px',
};

const CollapsibleSection = ({ header, children, expand, onClick }: CollapsibleSectionProps) => {

	return (
		<Container>
			<Header onClick={onClick}>
				<Title>{header}</Title>
				{expand ? <CaretUp style={expandToggleStyle} /> : <CaretDown style={expandToggleStyle} />}
			</Header>
			<Collapsible expanded={expand}>{children}</Collapsible>
		</Container>
	);
};

const Container = styled.div`
	padding-bottom: 16px;
	margin-bottom: 12px;
	border-bottom: 1px solid ${colors.grey10};
	width: 100%;
`;

const Collapsible = styled.div<{ expanded?: boolean }>`
	overflow: hidden;
	height: ${({ expanded }) => (expanded ? 'auto' : 0)};
	margin-top: ${({ expanded }) => (expanded ? '8px' : 0)};
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;

const Title = styled.p`
	font-weight: bold;
	color: ${colors.darkBlue};
`;

export default CollapsibleSection;
