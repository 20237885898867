import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 32px;
	margin-bottom: 24px;
`;

export const ListingsContainer = styled.div`
	display: flex;
	justify-content: center;
	overflow: auto;
	justify-content: flex-start;
	margin: 0 -24px;
`;

export const Listings = styled.div`
	display: flex;
	padding: 0 8px;
`;
