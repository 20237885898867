import styled from 'styled-components';

interface TextProps {
	noMargin?: boolean;
}

const Text = styled.p<TextProps>`
	margin: 0;
	font-size: 1rem;
`;

export default Text;
