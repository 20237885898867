import { flexBetween } from 'config/mixins';
import theme from 'config/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, fonts } = theme;

export const Container = styled.footer`
	padding: 120px;
	padding-bottom: 0px;
	background-color: ${colors.white};
	border-top: 1px solid ${colors.grey10};

	@media (max-width: 480px) {
		padding: 80px 20px;
		padding-bottom: 0px;
	}
`;

export const LinksContainer = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 80px;

	@media (max-width: 1100px) {
		margin-right: 0;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 25px;
	}
`;

export const Title = styled(Link)`
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	margin-bottom: 40px;
	text-decoration: none;
	color: ${colors.darkBlue};
	text-align: left;
	padding: 0;
	outline: none;
	transition: color 200ms ease-in-out;
	background-color: transparent;

	@media (max-width: 1100px) {
		font-size: ${fontSizes.medium};
	}

	&:hover,
	&:active {
		color: ${colors.pink};
	}
`;

export const FooterLink = styled(Link)`
	font-weight: ${fontWeights.normal};
	color: ${colors.grey60};
	text-decoration: none;
	margin-bottom: 40px;
	font-size: 14px;
	font-family: ${fonts.Gilroy};
	background-color: transparent;
	text-align: left;
	padding: 0;
	outline: none;

	:hover {
		color: ${colors.pink};
		text-decoration: underline;
	}
`;

export const Inner = styled.section`
	display: flex;
	justify-content: space-between;
	flex: 1;

	@media (max-width: 1100px) {
		flex-wrap: wrap;
	}
`;

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	width: 140px;
	justify-content: flex-start;
	margin: 0 10px 0 0;

	@media (max-width: 320px) {
		width: 120px;
	}
`;

export const LogoContainer = styled.div`
	width: 120px;
	margin-right: 80px;
	margin-bottom: 40px;
`;

export const SocialContainer = styled.div`
	width: 150px;
	@media (max-width: 650px) {
		width: 100%;
		margin-bottom: 40px;
		align-items: flex-start;
	}
`;

export const BottomLayout = styled.div`
	${flexBetween};
	margin-right: 200px;

	@media (max-width: 1100px) {
		margin: 0;
	}

	@media (max-width: 650px) {
		flex-direction: column-reverse;
		align-items: flex-start;
	}
`;

export const Copyright = styled.span`
	font-weight: ${fontWeights.normal};
	color: ${colors.grey60};
	font-size: ${fontSizes.xsmall};
`;
