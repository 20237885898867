// TODO: Add image mapping to to imgix.ts
// TODO: Add names
export default [
	{
		name: '',
		description:
			'At Capsicum Culinary Studio you will fuel your culinary passion through hands-on training and internationally recognised qualifications. Choose from our six campuses across the country and become one of the more than 6 000 graduates from the largest chef school in South Africa.',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/capsicum_logo.png',
		link: 'https://www.capsicumcooking.com/',
	},
	{
		name: '',
		description:
			'Study IIE Post Grad Degrees, Undergrad Degrees, Diplomas &amp; Higher Certificates at Varsity College an educational brand of The Independent Institute of Education (The IIE). SA’s leading private higher education provider. Contact us to find out more.',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/varcity_college_logo.png',
		link: 'https://www.varsitycollege.co.za/',
	},
	{
		name: '',
		description:
			'The IIE Vega’s teaching philosophy is built on the mantra of wisdomwithmagic, creating an academic environment that is based on experiential learning where creatives are trained in strategy and strategists in design-thinking. Enrol for IIE undergraduate and postgraduate degrees, diplomas, higher certificates and short courses in design, brand communication and brand management at Vega campuses across South Africa.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/vega_logo.png',
		link: 'https://www.vegaschool.com/',
	},
	{
		name: '',
		description:
			"At the Academic Institute of Excellence; we believe in a future of possibilities and abundance. A future filled with excellence made possible by a new generation of visionaries and leaders. Excellence is in our name! It's in our programmes as well as our people. Excellence rubs off onto our learners, it lives on campus and online.",
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/aie_logo.png',
		link: 'http://www.aie.ac/',
	},
	{
		name: '',
		description:
			'The Cape Peninsula University of Technology is at the heart of technology education and innovation in Africa. An internationally acclaimed institution, it is the only university of technology in the Western Cape and is the largest university in the region with an enrolment of more than 30 000 students.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/cput_logo.png',
		link: 'https://www.cput.ac.za/',
	},
	{
		name: '',
		description:
			'AFDA is the School for the Creative Economy offering an array of fully accredited outcomes-based higher certificates and degrees, in both Undergraduate and Postgraduate qualifications. Across the programmes, there are over 30 study areas to choose from including disciplines in Film, Business Innovation, Digital Technology, Live Performance, Television and Radio between four campuses across South Africa.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/afda_logo.png',
		link: 'https://www.afda.co.za/',
	},
	{
		name: '',
		description:
			'The IMM Graduate School strives to be the distance learning provider of choice and the centre of excellence for marketing, supply chain and business disciplines in Africa.',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/imm_graduate_school_logo.png',
		link: 'https://www.immgsm.ac.za/',
	},
	{
		name: '',
		description:
			'Rosebank College is an educational brand of The Independent Institute of Education (The IIE) and the Coolest College in South Africa. With seven campuses nationally and IIE qualifications offered through full-time, part-time and online – we are geared to empower you for the future.',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/rosebank_college_logo.png',
		link: 'https://www.rosebankcollege.co.za/',
	},
	{
		name: '',
		description:
			'Ideas can change the world. If your mind is #BrilliantlyDifferent, unleash its full potential at the birthplace of brilliant – the AAA School of Advertising.',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/aaa_logo.png',
		link: 'http://www.aaaschool.co.za/',
	},
	{
		name: '',
		description:
			'RICHFIELD Graduate Institute of Technology is South Africa’s most affordable Higher Education Institution and has been operating successfully for the past 28 years. Richfield has 33 Higher Education campuses and 43 TVET campuses nationally.',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/richfield_logo.png',
		link: 'http://www.rgit.co.za/',
	},
	{
		name: '',
		description: '',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/stadio_logo_dark.png',
		link: 'https://stadio.ac.za/',
	},
	{
		name: '',
		description: '',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/red_yellow_logo.png',
		link: 'https://www.redandyellow.co.za/',
	},

	{
		name: '',
		description: '',
		image:
			'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/eta_college_logo.png',
		link: 'https://www.etacollege.com/',
	},
	{
		name: '',
		description: '',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/ctu_logo.png',
		link: 'https://ctutraining.ac.za/',
	},
	{
		name: '',
		description: '',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/sacap_logo.png',
		link: 'https://www.sacap.edu.za/',
	},
	{
		name: '',
		description: '',
		image: 'https://storage.googleapis.com/digsconnect-imgix/parter_pictures_color/eduvos_logo.png',
		link: 'https://www.eduvos.com/',
	},
];
