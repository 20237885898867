const getFacebookShareLink = (path: string) =>
	`https://www.facebook.com/sharer/sharer.php?u=www.digsconnect.com${path}`;

const getEmailShareLink = (url: string) =>
	`mailto:?subject=Check out this listing I found&body=Hi, I found this listing and thought you might like it: ${url}`;

const getWhatsappShareLink = (url: string) =>
	`whatsapp://send?text=Check out this listing I found: ${url}!" data-action="share/whatsapp/share`;

const copyToClipboard = (toCopy: string) => {
	navigator.clipboard.writeText(toCopy);
};

export default {
	getFacebookShareLink,
	getEmailShareLink,
	getWhatsappShareLink,
	copyToClipboard,
};
