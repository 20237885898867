const flatten = arr => {
	if (arr.flat) {
		return arr.flat();
	}
	const flattenedArr = [];
	arr.forEach(item => {
		if (Array.isArray(item)) {
			flattenedArr.push(...item);
		} else {
			flattenedArr.push(item);
		}
	});
	return flattenedArr;
};

export default {
	flatten,
};
