import api from 'api';
import Input from 'lib/Input';
import { Select } from 'lib/Select';
import { SelectOption } from 'lib/Select';
import React, { useEffect, useState } from 'react';
import { Button } from 'lib/Button';
import styled from 'styled-components';
import theme from 'config/theme';
import { Checkbox } from 'lib/Checkbox';
import routes from 'config/routes';
import { ContactUsTypes } from './contactUsTypes';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import validateForm, {
	validateName,
	validateLastname,
	validateEmail,
	validateMessage,
	validateMobile,
	ValidateFormProps,
} from './validateFrom';
import { notifyFailure } from 'lib/Notifications';
import useSearch from 'hooks/useSearch';
import { useLocation } from 'react-router';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import MobileNumberInput from 'lib/MobileNumberInput';
import countryCodes from 'constants/countryCodes';
import authContainer from 'containers/auth';
import numberFormatService from 'services/numberFormatService';
import userContainer from 'containers/user';
import { Subscribe } from 'unstated';

const { colors, fontWeights, fonts } = theme;

const contactUsOptions: SelectOption[] = [
	{
		value: ContactUsTypes.Tenant,
		label: 'I need assistance as a tenant',
	},
	{
		value: ContactUsTypes.Landlord,
		label: 'I need assistance as a landlord',
	},
	{
		value: ContactUsTypes.Technical,
		label: 'Technical issues',
	},
	{
		value: ContactUsTypes.Invoicing,
		label: 'Invoicing and billing',
	},
	{
		value: ContactUsTypes.Partner,
		label: 'Partner with DigsConnect',
	},
	{
		value: ContactUsTypes.Other,
		label: 'Other',
	},
];

const QueryParamKey = {
	Reason: 'reason',
};

interface ContactUsProps {
	givenReason?: ContactUsTypes;
	formOnly?: boolean;
}
const ContactUsForm = ({ givenReason, formOnly }: ContactUsProps) => {
	const { getParam, setParam } = useSearch();
	const inputReason = getParam(QueryParamKey.Reason) || '';
	const { search } = useLocation();
	const [email, setEmail] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [region, setRegion] = useState('ZA');
	const [mobileNumber, setMobileNumber] = useState('');
	const [reason, setReason] = useState(givenReason || inputReason || '');
	const [message, setMessage] = useState('');
	const [loggedIn, setLoggedIn] = useState(false);
	const [validName, setValidName] = useState(false);
	const [validLastname, setValidLastname] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const [validMobile, setValidMobile] = useState(true);
	const [validMessage, setValidMessage] = useState(false);
	const [privacyConsentChecked, setPrivacyConsentChecked] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);
	const [showValidationErrors, setShowValidationErrors] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const getSelectOptionLabel = (validReason: string) => {
		let label = '';
		contactUsOptions.forEach(option => {
			if (validReason === option.value) {
				label = option.label;
			}
		});
		return label;
	};

	useEffect(() => {
		const formValues: ValidateFormProps = {
			firstname,
			lastname,
			email,
			mobileNumber,
			reason,
			message,
			privacyConsentChecked,
		};
		setValidName(validateName(firstname));
		setValidLastname(validateLastname(lastname));
		setValidEmail(validateEmail(email));
		if (mobileNumber != '') {
			setValidMobile(
				validateMobile(
					featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput)
						? numberFormatService.formatMobileNumber(
								(countryCodes as any[]).find(item => item.code === region).dial_code,
								mobileNumber,
						  )
						: mobileNumber,
				),
			);
		}
		setValidMessage(validateMessage(message));
		setCanSubmit(validateForm(formValues));
	}, [firstname, lastname, email, mobileNumber, reason, message, privacyConsentChecked]);

	const handleSubmit = async () => {
		setShowValidationErrors(true);
		if (!validEmail || !validMessage || !validName || !validLastname) {
			return;
		}
		if (mobileNumber != '' && !validMobile) {
			return;
		}
		if (inputReason !== '') {
			setReason(inputReason);
		}
		if (mobileNumber != '') {
			const formattedNumber = numberFormatService.formatMobileNumber(
				(countryCodes as any[]).find(item => item.code === region).dial_code,
				mobileNumber,
				true,
			);
			const res = await api.contactUs.contactUsV2(
				firstname,
				lastname,
				email,
				formattedNumber,
				reason,
				message,
			);
			if (res.status == 201) {
				setSubmitted(true);
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			} else {
				notifyFailure("You've exceeded your limit on contact us requests sent.");
			}
		} else {
			const res = await api.contactUs.contactUsV2NoMobile(
				firstname,
				lastname,
				email,
				reason,
				message,
			);

			if (res.status == 201) {
				setSubmitted(true);
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			} else {
				notifyFailure("You've exceeded your limit on contact us requests sent.");
			}
		}
		if (!loggedIn) {
			setFirstname('');
			setLastname('');
			setEmail('');
			setValidName(true);
			setValidLastname(true);
			setValidEmail(true);
		}
		setMobileNumber('');
		setReason('');
		setMessage('');
		setValidMobile(true);
		setValidMessage(true);
	};

	return (
		<>
			{submitted && !formOnly ? (
				<>
					<Container submitted={submitted}>
						<Title>Thank you for getting in touch!</Title>
						<SubTitle>We'll get back to you as soon as possiblle.</SubTitle>
						<Button link={routes.home}>Return to homepage</Button>
					</Container>
				</>
			) : (
				<>
					<Container formOnly={formOnly}>
						{!formOnly && (
							<>
								<Title>Contact Us</Title>
								<SubTitle>
									<span style={{ fontWeight: 700 }}>Please note: </span>DigsConnect{' '}
									<span style={{ fontWeight: 700 }}>DOES NOT </span> own or operate any student
									accommodation
								</SubTitle>
							</>
						)}
						<ItemContainer>
							<Subscribe to={[userContainer]}>
								{({ state }) => {
									if (state.v2.uuid) {
										setFirstname(state.v2.first_name);
										setLastname(state.v2.last_name);
										setEmail(state.v2.email.email);
									}
									return <></>;
								}}
							</Subscribe>
							<NameContainer>
								<Input
									key="name"
									placeholder={firstname || 'First name'}
									value={firstname}
									onChange={inputValue => {
										setFirstname(inputValue as string);
									}}
									isErrorState={!validName && showValidationErrors}
									errorMessage="Please enter a valid name withour letters"
								/>
								<Input
									key="lastname"
									placeholder={lastname || 'Last name'}
									value={lastname}
									onChange={inputValue => {
										setLastname(inputValue as string);
									}}
									isErrorState={!validLastname && showValidationErrors}
									errorMessage="Please enter a valid lastname without letters"
								/>
							</NameContainer>
							<Input
								key="email"
								placeholder={email || 'Email'}
								value={email}
								onChange={inputValue => {
									setEmail(inputValue as string);
								}}
								isErrorState={!validEmail && showValidationErrors}
								errorMessage="Please enter a valid email address"
							/>
							{featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput) ? (
								<MobileNumberInput
									country={region}
									mobileNumber={mobileNumber}
									onCountrySelect={setRegion}
									onMobileNumberChange={setMobileNumber}
									noBorder
									isErrorState={!validMobile && showValidationErrors}
									errorMessage="Please enter a valid mobile number"
								/>
							) : (
								<Input
									key="mobile_number"
									placeholder="Mobile number"
									value={mobileNumber}
									onChange={inputValue => {
										setMobileNumber(inputValue as string);
									}}
									isErrorState={!validMobile && showValidationErrors}
									errorMessage="Please enter a valid mobile number"
								/>
							)}
							{!givenReason && (
								<>
									<Select
										key="reason"
										options={contactUsOptions}
										placeholder={getSelectOptionLabel(reason) || 'Reason'}
										onChange={inputValue => {
											setReason(inputValue.value as string);
										}}
										isDisabled={!!inputReason}
									/>
									{!!showValidationErrors && reason == '' && (
										<ValidationError>Please select a reason</ValidationError>
									)}
								</>
							)}
							<TextAreaContainer>
								<TextAreaWraper>
									<TextArea
										name="message"
										key="message"
										id="message"
										value={message}
										placeholder="Write your message..."
										onInput={(e: any) => {
											setMessage(e.target.value);
										}}
									/>
									<TextAreaCount validLength={validMessage}>{message.length}/500</TextAreaCount>
								</TextAreaWraper>
								{!!showValidationErrors && !validMessage && (
									<ValidationError>
										Please include a message between 10 and 500 characters long.
									</ValidationError>
								)}
							</TextAreaContainer>
						</ItemContainer>
						<PrivacyContainer>
							<Checkbox
								checked={privacyConsentChecked}
								onClick={() => {
									setPrivacyConsentChecked(prev => !prev);
								}}
							>
								<CheckboxText>
									I agree to the{' '}
									<CheckboxLink href={routes.terms_and_conditions} target="_blank">
										terms and conditions
									</CheckboxLink>{' '}
									and{' '}
									<CheckboxLink href={routes.privacy} target="_blank">
										privacy policy
									</CheckboxLink>
								</CheckboxText>
							</Checkbox>
							<Button onClick={handleSubmit} isDisabled={!privacyConsentChecked}>
								Send
							</Button>
						</PrivacyContainer>
					</Container>
				</>
			)}
		</>
	);
};

export default ContactUsForm;

const TextAreaWraper = styled.div`
	position: relative;
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const CheckboxText = styled.p`
	line-height: 1.3;
	margin-bottom: 0;
`;

const PrivacyContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const ItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex-direction: column;
	}
`;
const NameContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex-direction: column;
		gap: 0px;
	}
`;
const CheckboxLink = styled.a`
	cursor: pointer;
	color: ${colors.darkTurquoise};
	font-weight: ${fontWeights.bold};
`;

const TextAreaContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const TextAreaCount = styled.div<{ validLength: Boolean }>`
	position: absolute;
	z-index: 1;
	right: 10px;
	bottom: 10px;
	color: ${({ validLength }) => (validLength ? colors.grey60 : colors.red)};
`;

const TextArea = styled.textarea`
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	background-color: ${colors.grey05};
	padding: 30px 20px;
	z-index: 0;
	border-radius: 20px;
	outline: none;
	height: 100px;
	width: 100%;
	&::placeholder {
		color: ${colors.lightBlue};
	}
`;

const SubTitle = styled.div`
	font-size: 20px;
	align-items: flex-start;
	color: ${colors.darkBlue};
`;
const Title = styled.div`
	font-weight: 700;
	font-size: 48px;
	color: ${colors.darkBlue};
	align-items: flex-start;
`;

const Container = styled.div<{ submitted?: boolean; formOnly?: boolean }>`
	display: flex;
	flex-direction: column;
	padding: ${({ formOnly }) => (formOnly ? '0px' : '24px')};
	margin-left: ${({ formOnly }) => (formOnly ? '0px' : '60px')};
	margin-right: 60px;
	align-items: ${({ submitted }) => (submitted ? 'center' : 'flex-start')};
	justify-content: ${({ submitted }) => (submitted ? 'center' : 'flex-start')};
	width: ${({ formOnly }) => (formOnly ? '60%' : '50%')};
	gap: ${({ submitted }) => (submitted ? '24px' : '24px')};
	background: ${colors.white};

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
`;
