const appendQueryParams = (url: string, queryParamString: string) => {
	const urlHasParams = url.includes('?');

	if (urlHasParams) {
		return `${url}&${queryParamString}`;
	}

	return `${url}?${queryParamString}`;
};

export default {
	appendQueryParams,
};
