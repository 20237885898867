import React from 'react';

import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import styled from 'styled-components';
import { ReactComponent as Shield } from 'assets/images/icons/shield.svg';
import { ReactComponent as RightArrow } from 'components/icons/arrow-right.svg';

import theme from 'config/theme';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors, fontWeights } = theme;

interface SafetyProps {
	hideFindOutMore?: boolean;
}

const SafetyTips = ({ hideFindOutMore }: SafetyProps) => {
	const [goTo] = useRouter();
	return (
		<Container>
			<TitleContainer>
				<Title>
					<Shield />
					Enquiry safety tips
				</Title>
				{!hideFindOutMore && (
					<TitleLinkTextContainer
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							goTo(routes.safety);
						}}
					>
						<LinkText>Find out more</LinkText>
						<RightArrow />
					</TitleLinkTextContainer>
				)}
			</TitleContainer>
			<Divider />
			<SafetyContentContainer>
				<NumberedList>
					<NumberedListItem>
						<span>Never make a payment </span>
						<span style={{ fontWeight: fontWeights.normal }}>
							to a landlord before verifying their authenticity. You could do this by asking to read
							over the lease agreement or going to view the property.
						</span>
					</NumberedListItem>
					<NumberedListItem>
						<span>Look out for trusted landlords </span>
						<span style={{ fontWeight: fontWeights.normal }}>
							as they have previously placed tenants through the DigsConnect platform.
						</span>
					</NumberedListItem>
					<NumberedListItem style={{ marginBottom: 0 }}>
						<span>Only share personal information when you are confident </span>
						<span style={{ fontWeight: fontWeights.normal }}>
							that the property and landlord are legitimate.
						</span>
					</NumberedListItem>
				</NumberedList>
			</SafetyContentContainer>
			{!hideFindOutMore && (
				<LinkTextContainer
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						goTo(routes.safety);
					}}
				>
					<LinkText>Find out more</LinkText>
					<RightArrow />
				</LinkTextContainer>
			)}
		</Container>
	);
};
export default SafetyTips;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 16px;
	border-radius: 16px;
	background: ${colors.darkTurquoiseFaded};

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		align-items: center;
	}
`;

const SafetyContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
`;

const Title = styled.div`
	display: flex;
	flex-direction: row;
	font-weight: 400;
	font-size: 20px;
	font-color: ${colors.darkBlue};
	align-items: center;
	flex: 1;
	gap: 16px;
`;

const NumberedList = styled.ol`
	list-style: decimal outside none;
	padding-left: 24px;
`;

const NumberedListItem = styled.li`
	list-style: decimal outside none;
	line-height: 20px;
	font-weight: bold;
	margin-bottom: 16px;
`;

const TitleLinkTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	color: ${colors.linkBlue};
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

const LinkTextContainer = styled.div`
	display: none;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	color: ${colors.linkBlue};
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: flex;
	}
`;

const LinkText = styled.a`
	color: ${colors.linkBlue};
	font-size: 16px;
	text-align: center;
`;

export const Divider = styled.div`
	border-bottom: 1px solid ${colors.grey10};
	width: 100%;
`;
