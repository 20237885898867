import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fonts, fontWeights } = theme;

const PageTitle = styled.h2`
	font-size: ${fontSizes.header};
	font-family: ${fonts.Gilroy};
	margin-bottom: 40px;
	font-weight: ${fontWeights.heavy};

	@media (max-width: 500px) {
		font-size: ${fontSizes.xxxlarge};
	}
`;

export default PageTitle;
