import theme from 'config/theme';
import styled from 'styled-components';

import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { ReactComponent as PlaySvg } from 'assets/images/icons/play.svg';
const { colors, fontSizes, fontWeights, fonts, letterSpacings } = theme;

export const HeroContainer = styled.div`
	margin-top: -110px;
`;

export const DesktopHero = styled.section`
	color: ${colors.white};
	height: 700px;

	@media (max-width: 500px) {
		display: none;
	}
`;

export const ImageContainer = styled.section`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
`;

export const TitleContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-left: 10vw;
	top: 36%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
`;

export const Title = styled.h1`
	font-size: 4.5em;
	font-weight: ${fontWeights.bold};
	color: ${colors.white};
	margin-bottom: 40px;
	width: 80%;

	@media (max-width: 1450px) {
		width: 100%;
		font-size: 3em;
	}
`;

export const PlayIcon = styled(PlaySvg)`
	fill: ${colors.white};
	height: 12px;
	width: 14px;
`;

export const MobileButtonContainer = styled.div`
	width: 85%;
	text-align: flex-start;
	font-size: 16px;
`;

export const TestimonialLinkAndCreateListingContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 32px;
	background-color: transparent;
	background-repeat: no-repeat;
	outline: none;

	@media (max-width: 500px) {
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
	}
`;

export const TestimonyLink = styled.a`
	font-weight: ${fontWeights.normal};
	color: ${colors.white};
	text-decoration: none;
	font-size: 14px;
	font-family: ${fonts.Gilroy};
	background-color: transparent;
	text-align: left;
	padding: 0;
	outline: none;

	:hover {
		text-decoration: underline;
	}
`;

export const Underline = styled.div`
	background-color: ${colors.white};
	border-radius: 4px;
	margin: 16px auto;
	width: 42px;
	height: 4px;
	position: absolute;
	left: 10vw;
`;

export const BgImg = styled.img`
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

export const MobileHero = styled.div`
	padding: 140px 24px 80px 24px;
	background-color: ${colors.blue};
	height: auto;

	@media (min-width: 500px) {
		display: none;
	}
`;

export const HeroTitleContainer = styled.div`
	margin-bottom: 24px;
`;

export const HeroTitle = styled.h1`
	color: ${colors.white};
	font-size: ${fontSizes.xxlarge};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	letter-spacing: ${letterSpacings.tight};
	margin-bottom: 0;
	position: relative;

	@media (min-width: 355px) {
		font-size: ${fontSizes.xxxlarge};
	}

	@media (min-width: 900px) {
		font-size: ${fontSizes.hero};
	}
`;

export const HeroScroll = styled(HeroTitle)`
	font-size: ${fontSizes.xxlarge};

	@media (min-width: 355px) {
		font-size: ${fontSizes.xxxlarge};
	}

	@media (min-width: 900px) {
		font-size: ${fontSizes.hero};
	}
`;

export const Banner = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	color: white;
	font-weight: 700;
	padding: 20px 15%;
	background-color: ${colors.blue};

	@media (max-width: 900px) {
		padding: 20px 10px;
	}
`;

export const BannerTextOverlay = styled.div<{ noFade?: boolean }>`
	position: absolute;
	background: ${({ noFade }) => (noFade ? 'transparent' : 'rgba(0, 0, 0, 0.4)')};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20%;
`;

export const BannerTitleText = styled.h1`
	font-size: 3em;
	font-weight: ${fontWeights.bold};
	color: ${colors.white};
	margin-bottom: 40px;
`;

export const BannerImage = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;

export const MobileImage = styled.img`
	background: ${colors.grey60};
	height: 420px;
	object-fit: cover;
`;

export const MobileLandlordTitleContainer = styled.div`
	text-align: flex-start;
`;

export const MobileTitleContainer = styled.div`
	margin-top: 32px;
	margin-bottom: 24px;
	text-align: center;
`;

export const MobileLandlordTitle = styled.h1`
	color: ${colors.white};
	font-size: 28px;
	font-weight: 700;
	letter-spacing: ${letterSpacings.tight};
	margin-bottom: 0;
	position: relative;
`;
export const MobileTitle = styled.h1`
	color: ${colors.white};
	font-size: 30px;
	font-weight: 600;
	letter-spacing: ${letterSpacings.tight};
	margin-bottom: 0;
	line-height: 30px;
	position: relative;
`;

export const MobileBanner = styled.div`
	height: 420px;
	position: relative;

	@media (min-width: 500px) {
		display: none;
	}
`;

export const MobileLandlordBannerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: flex-start;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 124px 16px 40px 16px;
	gap: 24px;
`;

export const MobileBannerContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 132px 24px 80px 24px;
`;

export const SectionTitle = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	margin-bottom: 48px;
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

export const TestimonialVideoButton = styled.div`
	display: flex;
	flex-direction: row;
	background-color: trandparent;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: ${colors.white};
	gap: 10px;

	:hover {
		text-decoration: underline;
	}
`;

export const Text = styled.p`
	text-align: center;
	font-weight: ${fontWeights.bold};
	font-size: 16px;
`;
