import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { colors, fontSizes, fontWeights, radii, fonts } = theme;

export const Container = styled.section`
	background: ${({ background }) => background || colors.white};
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 48px 0;

	@media (max-width: 900px) {
		height: 280px;
		padding: 48px 0;
	}
`;

export const Title = styled.h2`
	font-size: ${fontSizes.xxlarge};
	font-family: ${fonts.Gilroy};
	font-weight: ${fontWeights.bold};
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		text-align: center;
		width: 100%;
		padding-left: 24px;
	}
`;

export const PartnersContainer = styled.section`
	${flexCenter};
	flex: 1;
	width: 100%;
	gap: 69px;

	@media (max-width: 900px) {
		gap: 30px;
	}
`;

export const PartnerImg = styled.img`
	width: 70%;
`;

export const PartnerLink = styled(Link)`
	${flexCenter};
	margin: 10px;
	height: 120px;
	width: 120px;
	background-color: ${colors.lightblue};
	gap: 69px;

	@media (max-width: 900px) {
		height: 100px;
		min-height: 100px;
		width: 100px;
		min-width: 100px;
	}

	@media (max-width: 900px) {
		height: 80px;
		min-height: 80px;
		width: 80px;
		min-width: 80px;
		margin: 5px;
	}
`;
