import * as React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus.svg';

import Text from './Text';

const { colors, inputs } = theme;

interface NumberInputProps {
	label?: string;
	value?: string | number;
	isErrorState?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	isBlue?: boolean;
	onChange(value: string | number): void;
}

const STEP_ICON_SIZE = 12;

const buttonStyle = {
	minWidth: STEP_ICON_SIZE,
	minHeight: STEP_ICON_SIZE,
	borderRadius: 5,
	border: `2px solid ${colors.pink}`,
	fill: colors.pink,
};

const IconButton = styled.button`
	border: none;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-tap-highlight-color: none;
`;

const LabelText = styled(Text)<{ isBlue?: boolean }>`
	color: ${({ isBlue }) => (isBlue ? colors.darkBlue : colors.grey60)};
`;

const NumberInput = ({
	value,
	label,
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	disabled = false,
	isBlue,
	onChange,
}: NumberInputProps) => (
	<>
		<MainContainer>
			<LabelText isBlue={isBlue}>{label}</LabelText>
			<ControlContainer>
				<IconButton>
					<MinusIcon
						style={buttonStyle}
						onClick={() => {
							if (+(value || 0) <= 0) {
								onChange(0);
								return;
							}
							onChange(+(value || 0) - 1);
						}}
					/>
				</IconButton>
				<InputContainer>
					<StandardInput
						type="number"
						value={value}
						disabled={disabled}
						onChange={event => onChange(event.target.value)}
					/>
				</InputContainer>
				<IconButton>
					<PlusIcon style={buttonStyle} onClick={() => onChange(+(value || 0) + 1)} />
				</IconButton>
			</ControlContainer>
		</MainContainer>
		{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
	</>
);

export default NumberInput;

const InputContainer = styled.div`
	background-color: ${colors.grey05};
	border-radius: ${inputs.borderRadius};
	padding: 16px;
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 64px;
	min-width: 64px;
	margin: 0 12px;
`;

const StandardInput = styled.input`
	background-color: ${colors.grey05};
	color: ${colors.darkBlue};
	width: 100%;
	outline: none;
	text-align: center;
	&:disabled {
		color: ${colors.grey60};
		&:hover {
			cursor: not-allowed;
		}
	}
	::placeholder {
		text-overflow: ellipsis;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: textfield;
	}

	-webkit-appearance: none;
	-moz-appearance: textfield;
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const MainContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: flex-end;
`;

const ControlContainer = styled.div`
	display: flex;
	align-items: center;
`;
