import React, { useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as LeftArrow } from 'assets/images/icons/arrow-left-white.svg';
import { ReactComponent as RightArrow } from 'assets/images/icons/arrow-right-white.svg';
import { Errors } from 'constants/errors';

const { colors, zIndices } = theme;

const ArrowType = {
	Left: 'left',
	Right: 'right',
};

const ArrowButton = ({ type, onClick }) => {
	return (
		<Arrow type={type} onClick={onClick}>
			<ArrowInner>
				{type === ArrowType.Left && <LeftArrow />}
				{type === ArrowType.Right && <RightArrow />}
			</ArrowInner>
		</Arrow>
	);
};

const Carousel = ({ images, isFlexibleHeight }) => {
	const [index, setIndex] = useState(0);

	const handleRightClick = e => {
		e.stopPropagation();
		setIndex(i => (i === images.length - 1 ? 0 : i + 1));
	};

	const handleLeftClick = e => {
		e.stopPropagation();
		setIndex(i => (i === 0 ? images.length - 1 : i - 1));
	};

	const image = images && images[index];

	if (!image) {
		return null;
	}

	return (
		<SlideContainer isFlexibleHeight={isFlexibleHeight}>
			<SlideImage
				src={`${image.split('?')[0]}?${image.split('?')[1] || 'auto=compress&auto=format'}`}
				alt={Errors.img_alt_error}
			/>
			<ArrowsOverlay>
				<ArrowButton type={ArrowType.Left} onClick={handleLeftClick} />
				<ArrowButton type={ArrowType.Right} onClick={handleRightClick} />
			</ArrowsOverlay>
		</SlideContainer>
	);
};

export default Carousel;

const SlideImage = styled.img`
	height: 100%;
	width: 100%;
	display: block;
	object-fit: cover;
`;

const SlideContainer = styled.div`
	height: ${({ isFlexibleHeight }) => (isFlexibleHeight ? '100%' : '200px')};
	width: 100%;
`;

const Arrow = styled.div`
	background-color: transparent;
	height: 100%;
	width: 40px;
	position: absolute;
	${({ type }) => (type === ArrowType.Left ? 'left: 0' : 'right: 0')};
	top: 0;
	z-index: ${zIndices.modal};
	flex-direction: column;
	justify-content: center;
	display: flex;
	${({ type }) => (type === ArrowType.Left ? 'align-items: flex-end' : 'align-items: flex-start')};
`;

const ArrowInner = styled.div`
	background-color: ${colors.darkBlue};
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 200ms;

	&:hover {
		transform: scale(1.05);
	}
`;

const ArrowsOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: all 200ms;

	&:hover {
		opacity: 1;
	}
`;
