import SocialLinks from 'components/common/social-links';
import Logo from 'components/icons/logo';
import social from 'config/social';
import theme from 'config/theme';
import React from 'react';
import routes from 'config/routes';
import { Link } from 'react-router-dom';

import {
	BottomLayout,
	Container,
	Copyright,
	FooterLink,
	Inner,
	LinksContainer,
	LogoContainer,
	Section,
	SocialContainer,
	Title,
} from './styles';
import styled from 'styled-components';
import preval from 'preval.macro';
import Paragraph from '../paragraph/index';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import useRouter from 'hooks/useRouter';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const BUILD_DATE = preval`module.exports = new Date().toLocaleString();`;
const { colors, fontWeights, fonts } = theme;

const Footer = () => {
	const [goTo] = useRouter();

	if (featureFlagContainer.isEnabled(FeatureFlag.Simplify)) {
		return (
			<FooterContainer>
				<FooterLinksContainer>
					<FooterLogoContainer>
						<Logo color={colors.blue} />
					</FooterLogoContainer>
					<FooterInner>
						<Section>
							<FooterLinkTitleText to={routes.digs_search}> Find a Digs </FooterLinkTitleText>
							<FooterLinkText to={routes.about}> About us </FooterLinkText>
							<FooterLinkText to={routes.landlord_resources}> Landlord resources </FooterLinkText>
							<FooterLinkText href={routes.safety} as="a">
								{' '}
								Safety on DigsConnect{' '}
							</FooterLinkText>
						</Section>
						<Section>
							<FooterLinkTitleText
								to={routes.create_listing}
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									goTo(routes.create_listing);
								}}
							>
								Create a listing
							</FooterLinkTitleText>
							<FooterLinkText to={routes.pricing}> Pricing </FooterLinkText>
							<FooterLinkText to={routes.faqs}> FAQs </FooterLinkText>
						</Section>
						<Section>
							<FooterLinkTitleText to={routes.contact_us}>
								{' '}
								Contact DigsConnect{' '}
							</FooterLinkTitleText>
							<FooterLinkText to={routes.terms_and_conditions}>
								{' '}
								Terms and Conditions{' '}
							</FooterLinkText>
							<FooterLinkText to={routes.privacy}> Privacy policy </FooterLinkText>
						</Section>
					</FooterInner>
				</FooterLinksContainer>

				<FooterBottomContainer>
					<SocialContainer>
						<SocialLinks />
					</SocialContainer>
					<Paragraph style={{ textAlign: 'center' }}>
						<Copyright>
							© 2019 - {new Date().getFullYear()} DigsConnect. All Rights Reserved. Terms &
							conditions - build: {BUILD_DATE}
						</Copyright>
					</Paragraph>
				</FooterBottomContainer>
			</FooterContainer>
		);
	}

	return (
		<Container>
			<LinksContainer>
				<LogoContainer>
					<Logo color={colors.blue} />
				</LogoContainer>
				<Inner>
					<Section>
						<Title to={routes.how_it_works}> How It Works </Title>
						<FooterLink href={routes.partners} as="a">
							{' '}
							Our Partners{' '}
						</FooterLink>
						<FooterLink href={routes.safety} as="a">
							{' '}
							Safety on DigsConnect{' '}
						</FooterLink>
						<FooterLink href={social.blog} as="a">
							{' '}
							Blog{' '}
						</FooterLink>
					</Section>
					<Section>
						<Title
							to={routes.create_listing}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								goTo(routes.create_listing);
							}}
						>
							{' '}
							Create a listing{' '}
						</Title>
						<FooterLink to={routes.digs_search}> Find a Digs </FooterLink>
						<FooterLink to={routes.landlord_resources}> Landlord resources </FooterLink>
						<FooterLink
							href={
								'https://storage.googleapis.com/digsconnect-static/The%20State%20of%20Student%20Housing%20Report%202020.pdf'
							}
							as="a"
						>
							Student Housing Report
						</FooterLink>
					</Section>
					<Section>
						<Title to={routes.manage_digs}> Manage Digs </Title>
						<FooterLink to={routes.pricing}> Pricing </FooterLink>
					</Section>
					<Section>
						<Title to={routes.contact_us}> Contact DigsConnect </Title>
						<FooterLink to={routes.terms_and_conditions}> Terms and Conditions </FooterLink>
						<FooterLink to={routes.privacy}> Privacy policy </FooterLink>
						<FooterLink to={routes.faqs}> FAQs </FooterLink>
					</Section>
				</Inner>
			</LinksContainer>

			<BottomLayout>
				<Paragraph>
					<Copyright>
						© 2019 - {new Date().getFullYear()} DigsConnect. All Rights Reserved. Terms & conditions
						- build: {BUILD_DATE}
					</Copyright>
				</Paragraph>

				<SocialContainer>
					<SocialLinks />
				</SocialContainer>
			</BottomLayout>
		</Container>
	);
};

const FooterContainer = styled.div`
	margin: auto;
	max-width: 900px;
	margin-top: 72px;
	padding: 16px;
`;

const FooterLinksContainer = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-right: 0;
		flex-direction: column;
		align-items: center;
	}
`;

const FooterInner = styled.section`
	display: flex;
	justify-content: space-between;
	flex: 1;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
	}
`;

const FooterLinkText = styled(Link)`
	font-weight: ${fontWeights.normal};
	color: ${colors.grey60};
	text-decoration: none;
	margin-bottom: 40px;
	font-size: 14px;
	font-family: ${fonts.Gilroy};
	background-color: transparent;
	text-align: left;
	padding: 0;
	outline: none;

	:hover {
		color: ${colors.pink};
		text-decoration: underline;
	}

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		text-align: center;
		font-size: 16px;
		line-height: 1.2;
	}
`;

const FooterLinkTitleText = styled(FooterLinkText)`
	font-weight: 700;
	color: ${colors.darkBlue};
`;

const FooterLogoContainer = styled.div`
	width: 120px;
	margin-right: 80px;
	margin-bottom: 40px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-right: 0;
		width: auto;
	}
`;

const FooterBottomContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export default React.memo(Footer);
