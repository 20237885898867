import React, { useEffect, useRef } from 'react';
import { Popup, PopupContainer } from './CommonStyles';

export const ClosablePopup = ({
	triggerElementRef,
	children,
	onClose,
	width = '',
	rightAlign = false,
}) => {
	const popupElementRef = useRef();

	useEffect(() => {
		const eventName = 'mousedown';
		const clickHandler = e => {
			if (popupElementRef.current.contains(e.target)) {
				return;
			}
			if (
				triggerElementRef &&
				triggerElementRef.current &&
				e.target === triggerElementRef.current
			) {
				return;
			}
			if (
				triggerElementRef &&
				triggerElementRef.current &&
				triggerElementRef.current.contains(e.target)
			) {
				return;
			}
			onClose();
		};
		document.addEventListener(eventName, clickHandler);

		return () => document.removeEventListener(eventName, clickHandler);
		// TODO: Fix and test
		// eslint-disable-next-line
	}, []);

	return (
		<PopupContainer>
			<Popup ref={popupElementRef} rightAlign={rightAlign} width={width}>
				{children}
			</Popup>
		</PopupContainer>
	);
};
