import React from 'react';

import ListingCardMobile from 'components/digs-search-v2/ListingCardMobile';
import { Container, Listings, ListingsContainer } from './styles';
import { listingService } from 'services/listingService';
import { SubTitle } from '../SubTitle';
import { analyticsService, EventName } from 'services/analyticsService';

const SimilarListingsAccordion = ({ listings, titleText }) => {
	const handleListingCardClick = listing => {
		const link = listingService.getViewListingLink(listing);
		analyticsService.trackEvent(EventName.SimilarListingClick, {
			similar_listing_id: listing.uuid,
		});
		window.open(link, '_self');
	};

	return (
		<Container>
			<SubTitle>{titleText}</SubTitle>
			<ListingsContainer>
				<Listings>
					{listings.map((listing, i) => (
						<ListingCardMobile
							key={listing.uuid}
							{...listing}
							onClick={() => handleListingCardClick(listing)}
							widthPx={280}
							fixedHeightPx={320}
						/>
					))}
				</Listings>
			</ListingsContainer>
		</Container>
	);
};

export default SimilarListingsAccordion;
