import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import numberFormatService from 'services/numberFormatService';
import listingService from 'services/listingService';
import { ReactComponent as BedroomIcon } from 'assets/images/icons/bedroom.svg';
import { ReactComponent as BathroomIcon } from 'assets/images/icons/bathroom.svg';
import { BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { Room, Currency, Listing } from 'models/listing';
import { imgixFallback } from 'config/imgix';
import { Button } from 'lib/Button';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { ReactComponent as PeopleIcon } from 'assets/images/digs-listing/people.svg';
import { RoomType } from 'models/property';
import urlUtils from 'utils/urlUtils';

const { colors } = theme;

interface SingleRoomCardProps {
	room: Room;
	isSelected?: boolean;
	horizontalPadding?: number;
	currency: Currency;
	listing: Listing;
	selectable?: boolean;
	hideSelectButton?: boolean;
	showRoomDetails?: boolean;
	showRoomAvailability?: boolean;
	onClick?(room: Room): void;
}

const addImgixParams = (url: string): string =>
	urlUtils.appendQueryParams(url, 'auto=compress&auto=format&fit=crop&w=300&h=160');

export const SingleRoomCard = ({
	room,
	isSelected,
	horizontalPadding,
	listing,
	selectable,
	hideSelectButton,
	showRoomDetails,
	showRoomAvailability,
	onClick,
}: SingleRoomCardProps) => {
	const getPaymentPeriodDescription = (): string => {
		if (room.bedroom_type === RoomType.Shared) {
			return `/ per person`;
		}

		return `/ per ${listingService.getBillingCyclePeriod(listing)}`;
	};

	return (
		<RoomCard
			hasClickHandler={Boolean(selectable && onClick)}
			onClick={() => selectable && onClick && onClick(room)}
			isSelected={isSelected}
			horizontalPadding={horizontalPadding}
		>
			<div>
				<RoomImage
					src={room.photos[0] ? addImgixParams(room.photos[0].url) : addImgixParams(imgixFallback)}
				/>
				<TitleContainer>
					<HeavyText>{room.title}</HeavyText>
					{Boolean(room.guests) && (
						<OccupantsDisplay>
							<span>{room.guests}</span>
							<PeopleIcon style={{ marginLeft: 8 }} />
						</OccupantsDisplay>
					)}
				</TitleContainer>
			</div>
			<div>
				<Item largerMargin>
					<PriceText>
						{numberFormatService.formatCurrency(
							room.price,
							listingService.getCurrencySymbol(listing.currency),
						)}
					</PriceText>
					<PaymentPeriod>{getPaymentPeriodDescription()}</PaymentPeriod>
				</Item>
				<Item>
					<BedroomIcon style={{ marginRight: 8 }} />
					<IconText>{room.bedroom_type} room</IconText>
					{selectable && !hideSelectButton && (
						<SelectButton isSelected={isSelected}>Select{isSelected && 'ed'}</SelectButton>
					)}
				</Item>
				<Item>
					<BathroomIcon style={{ marginRight: 8 }} />
					<IconText>{room.bathroom_type} bathroom</IconText>
				</Item>
				{room.active || listing.external
					? showRoomDetails && (
							<Button isOutline height={32} onClick={onClick}>
								Show room details
							</Button>
					  )
					: showRoomAvailability && (
							<Button isOutline isDisabled height={32}>
								SOLD OUT
							</Button>
					  )}
			</div>
		</RoomCard>
	);
};

interface RoomCardProps {
	horizontalPadding?: number;
	isSelected?: boolean;
	hasClickHandler?: boolean;
}
interface SelectButtonProps {
	isSelected?: boolean;
}
const SelectButton = styled.div<SelectButtonProps>`
	border: 1px solid ${({ isSelected }) => (isSelected ? colors.pink : colors.grey20)};
	border-radius: 16px;
	padding: 4px 8px;
	margin-left: auto;
	font-size: 14px;
	font-weight: 600;
	color: ${({ isSelected }) => (isSelected ? colors.pink : colors.grey60)};
`;

const RoomCard = styled.div<RoomCardProps>`
	padding: ${({ horizontalPadding }) => horizontalPadding || 16}px;
	border-radius: 8px;
	width: 50%;
	max-width: 320px;
	min-width: 250px;
	border: ${({ isSelected }) => (isSelected ? '2px' : '1px')} solid
		${({ isSelected }) => (isSelected ? colors.pink : colors.grey10)};
	margin-right: 16px;
	box-shadow: ${({ isSelected }) => (isSelected ? '0px 7px 15px rgba(0, 0, 0, 0.1)' : 'none')};
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:hover {
		${({ hasClickHandler }) => (hasClickHandler ? 'cursor: pointer;' : '')}
	}

	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		min-width: 0;
	}
`;

const RoomImage = styled.img`
	border-radius: 8px;
	height: 160px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 12px;
`;

const HeavyText = styled.div`
	font-size: 16px;
	font-weight: 600;
	height: 32px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const PriceText = styled(HeavyText)`
	font-size: 20px;
`;

const PaymentPeriod = styled.span`
	font-weight: 400;
	font-size: 12px;
	color: ${colors.grey60};
	margin-left: 4px;
`;

const Item = styled.div<{ largerMargin?: boolean }>`
	display: flex;
	margin-bottom: ${({ largerMargin }) => (largerMargin ? `16px` : '12px')};
	align-items: center;
`;

const TitleContainer = styled(Item)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const IconText = styled.div`
	color: ${colors.grey60};
`;

const OccupantsDisplay = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;
	font-weight: 600;
	margin-left: 16px;
`;
