import React from 'react';

import Resize from '@tiaanduplessis/react-resize';

import Carousel from 'components/common/carousel';
import partnersConfig from 'config/partners';
import theme from 'config/theme';
import { Container, PartnerImg, PartnerLink, PartnersContainer, Title } from './styles';
import routes from 'config/routes';
import urlUtils from 'utils/urlUtils';

const { colors } = theme;
const BREAKPOINT_WIDTH = 1150;
const PAGE_SIZE_MOBILE = 3;
const PAGE_SIZE_DESKTOP = 6;

const chunks = (array, size) =>
	array.map((e, i) => (i % size === 0 ? array.slice(i, i + size) : null)).filter(e => e);

const AcademicPartners = ({ background }) => {
	return (
		<Container background={background}>
			<Title>Trusted by</Title>
			<Resize>
				{({ width }) => (
					<Carousel positionMarkerColor={colors.darkBlue}>
						{chunks(
							partnersConfig,
							width < BREAKPOINT_WIDTH ? PAGE_SIZE_MOBILE : PAGE_SIZE_DESKTOP,
						).map(current => (
							<PartnersContainer key={current[0].link}>
								{current.map(({ link, name, image }) => (
									<PartnerLink draggable={false} key={link} to={routes.partners}>
										<PartnerImg
											draggable={false}
											src={urlUtils.appendQueryParams(
												image,
												`auto=compress&auto=format&w=160&h=160`,
											)}
											alt={name}
										/>
									</PartnerLink>
								))}
							</PartnersContainer>
						))}
					</Carousel>
				)}
			</Resize>
		</Container>
	);
};

export default AcademicPartners;
