import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

const { fontWeights, fontSizes, colors } = theme;

const Container = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.blue};
`;

const Title = styled.h1`
	font-size: ${fontSizes.xxlarge};
	font-weight: ${fontWeights.bold};
	color: ${colors.white};
`;

interface TitleBar {
	title?: string;
}

export const TitleBar = ({ title }: TitleBar) => (
	<Container>
		<Title>{title}</Title>
	</Container>
);
