import emailRegex from 'utils/email-regex';
import phoneRegex from 'utils/phone-regex';
import namesRegex from 'utils/names-regex';

export interface ValidateFormProps {
	firstname: string;
	lastname: string;
	email: string;
	mobileNumber: string;
	reason: string;
	message: string;
	privacyConsentChecked: boolean;
}

export interface ValidateEmailProps {
	email: string;
}

const MIN_MESSAGE_LENGTH = 10;
const MAX_MESSAGE_LENGTH = 500;

export const validateName = (name: string) => {
	return namesRegex.test(String(name));
};
export const validateLastname = (lastname: string) => {
	return namesRegex.test(String(lastname));
};
export const validateMessage = (message: string) => {
	return message.length >= 10 && message.length <= 500;
};
export const validateMobile = (mobile: string) => {
	return phoneRegex.test(String(mobile).toLowerCase());
};
export const validateEmail = (email: string) => {
	return emailRegex.test(String(email).toLowerCase());
};
const validateForm = ({
	firstname,
	lastname,
	email,
	mobileNumber,
	reason,
	message,
	privacyConsentChecked,
}: ValidateFormProps) => {
	if (!privacyConsentChecked) {
		return false;
	}
	if (!firstname) {
		return false;
	}
	if (!lastname) {
		return false;
	}
	if (!emailRegex.test(String(email).toLowerCase())) {
		return false;
	}
	if (!phoneRegex.test(mobileNumber)) {
		return false;
	}
	if (!reason) {
		return false;
	}
	if (!message) {
		return false;
	} else {
		if (message.length > MAX_MESSAGE_LENGTH || message.length < MIN_MESSAGE_LENGTH) {
			return false;
		}
	}
	return true;
};
export default validateForm;
