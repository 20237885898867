import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { ReactComponent as WhiteRightArrow } from 'assets/images/digs-listing/white-right-arrow.svg';
import { ReactComponent as RightArrow } from 'assets/images/digs-listing/right-arrow.svg';
import { ReactComponent as WhiteLeftArrow } from 'assets/images/digs-listing/white-left-arrow.svg';
import { ReactComponent as LeftArrow } from 'assets/images/digs-listing/left-arrow.svg';
import { Currency, Listing } from 'models/listing';
import { Room } from 'models/property';
import { SubTitle } from './booking-v3/styled-subcomponents/SubTitle';

const { colors, boxShadow } = theme;

interface RoomCarouselProps {
	rooms: Room[];
	maxHorizontalCards: number;
	CardComponent: any;
	iconSize?: number;
	iconTop?: number;
	selectedRoomId?: string;
	horizontalPadding?: number;
	arrowsOutsideCard?: boolean;
	currency: Currency;
	listing: Listing;
	carouselTitle?: string;
	selectable?: boolean;
	hideSelectButton?: boolean;
	index?: number;
	showRoomDetails?: boolean;
	showRoomAvailability?: boolean;
	onRoomClick?(room: Room): void;
}

const defaultIconSize = 12;

export const RoomCarousel = ({
	rooms,
	maxHorizontalCards,
	CardComponent,
	iconSize = defaultIconSize,
	iconTop = 0,
	selectedRoomId = '',
	horizontalPadding,
	currency,
	listing,
	carouselTitle,
	index,
	selectable,
	hideSelectButton,
	showRoomDetails,
	showRoomAvailability,
	onRoomClick,
}: RoomCarouselProps) => {
	const [roomIndex, setRoomIndex] = useState(0);

	useEffect(() => {
		if (index !== undefined) {
			setRoomIndex(index);
		}
	}, [index]);

	const width = useScreenWidth();

	const handleNextClick = () => {
		setRoomIndex(prevIndex => {
			if (prevIndex + maxHorizontalCards > rooms.length - 1) {
				return rooms.length - 1;
			}
			return prevIndex + maxHorizontalCards;
		});
	};

	const handlePrevClick = () => {
		setRoomIndex(prevIndex => {
			if (prevIndex - maxHorizontalCards < 0) {
				return 0;
			}
			return prevIndex - maxHorizontalCards;
		});
	};

	if (!rooms || !rooms.length) {
		return null;
	}

	let adjustedRoomIndex = roomIndex;
	let adjustedMaxHorizonalCards = maxHorizontalCards;

	if (width <= BREAKPOINT_TABLET_LARGE_PX) {
		adjustedRoomIndex = 0;
		adjustedMaxHorizonalCards = rooms.length;
	}

	return (
		<>
			{!!carouselTitle && (
				<TitleContainer>
					<SubTitle noMargin>{carouselTitle}</SubTitle>
					<div className="button-container">
						{rooms.length > maxHorizontalCards && (
							<UpperScrollIcon onClick={handlePrevClick}>
								<WhiteLeftArrow width={iconSize} height={iconSize} />
							</UpperScrollIcon>
						)}
						{rooms.length > maxHorizontalCards && (
							<UpperScrollIcon onClick={handleNextClick}>
								<WhiteRightArrow width={iconSize} height={iconSize} />
							</UpperScrollIcon>
						)}
					</div>
				</TitleContainer>
			)}
			<Container noOutdent={!!carouselTitle}>
				{rooms
					.map((bedroom, i) => ({ ...bedroom, title: bedroom.title || `Bedroom ${i + 1}` }))
					.slice(adjustedRoomIndex, adjustedRoomIndex + adjustedMaxHorizonalCards)
					.map((room, i) => (
						<CardComponent
							hideSelectButton={hideSelectButton}
							key={i}
							selectable={selectable}
							onClick={() => onRoomClick && onRoomClick(room)}
							isSelected={selectedRoomId === room.uuid}
							horizontalPadding={horizontalPadding}
							room={room}
							currency={currency}
							listing={listing}
							showRoomDetails={showRoomDetails}
							showRoomAvailability={showRoomAvailability}
						/>
					))}
				{rooms.length > adjustedRoomIndex + adjustedMaxHorizonalCards &&
					!carouselTitle &&
					index === undefined && (
						<ScrollIconRight
							onClick={handleNextClick}
							size={iconSize + 16}
							top={iconTop}
							horizontalPadding={horizontalPadding}
						>
							<RightArrow width={iconSize} height={iconSize} />
						</ScrollIconRight>
					)}
				{adjustedRoomIndex > 0 && !carouselTitle && index === undefined && (
					<ScrollIconLeft
						onClick={handlePrevClick}
						size={iconSize + 16}
						top={iconTop}
						horizontalPadding={horizontalPadding}
					>
						<LeftArrow width={iconSize} height={iconSize} />
					</ScrollIconLeft>
				)}
			</Container>
		</>
	);
};

const defaultHorizontalMargin = 8;

const Container = styled.div<{ noOutdent?: boolean }>`
	display: flex;
	position: relative;
	max-width: 760px;
	margin-left: ${({ noOutdent }) => (noOutdent ? '0px' : '-8px')};
	margin-right: -8px;
	margin-bottom: 32px;
	overflow-x: auto;

	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		overflow-x: visible;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	margin: 18px 0;
	align-items: center;
	justify-content: space-between;

	.button-container {
		display: flex;
	}
`;

const UpperScrollIcon = styled.div`
	box-shadow: ${boxShadow.small};
	border-radius: 50%;
	border: 1px solid ${colors.darkBlue};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.darkBlue};
	height: 40px;
	width: 40px;
	margin-left: 8px;
	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		display: none;
	}
`;

const ScrollIcon = styled.div<{ size: number; top?: number }>`
	box-shadow: ${boxShadow.small};
	border-radius: 50%;
	border: 1px solid ${colors.grey10};
	cursor: pointer;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.white};
	position: absolute;
	${({ top }) =>
		top
			? `
      top: ${top}px;
    `
			: `
      top: 50%;
      transform: translateY(-50%);
    `}
`;

const ScrollIconLeft = styled(ScrollIcon)<{ size: number; horizontalPadding?: number }>`
	left: -${({ size, horizontalPadding }) => size / 2 - (horizontalPadding || defaultHorizontalMargin)}px;
`;

const ScrollIconRight = styled(ScrollIcon)<{ size?: number; horizontalPadding?: number }>`
	right: -${({ size, horizontalPadding }) => size / 2 - (horizontalPadding || defaultHorizontalMargin)}px;
`;
