import { flexCenter } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { colors, fontSizes, radii } = theme;

export const ItemContainer = styled.li`
	${flexCenter}
	width: 100%;
	height: 100%;
	position: absolute;
	transition: transform 1s;
	user-select: none;
`;

export const Arrow = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: ${fontSizes.xlarge};
	width: 3.5em;
	height: 3.5em;
	border-radius: ${radii.half};
	color: ${colors.white};
	background-color: ${colors.darkBlue};
	transition: opacity 0.3s;
	opacity: 0;
	outline: none;
	position: absolute;
	${({ offsetSide }) => `${offsetSide}: 1em;`}

	@media (max-width: 600px) {
		display: none;
	}
`;

export const CarouselContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const PreviewContainer = styled.ul`
  ${flexCenter}
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;

  @media (max-width: 900px) {
    border-radius: ${radii.none};
  }

  ${({ variant }) =>
		variant === 'thumbnail' &&
		`
    border-radius: 1em;
    height: unset;
    flex-grow: 1;
    & ${Arrow} {
      opacity: 0.4;
    }
  `}

  & ${ItemContainer} {
    border-radius: ${radii.none};
    padding-bottom: ${({ variant }) => (variant === 'default' ? '0.9em' : 0)};
  }

  &:hover ${Arrow} {
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
`;

export const PositionContainer = styled.div`
	position: absolute;
	bottom: 0;
`;

export const RoundedPositionMarker = styled.button`
	margin: 0.4em;
	padding: 0;
	width: 0.75em;
	height: 0.75em;
	border-radius: 100%;
	opacity: 0.6;
	transition: opacity 0.3s, width 0.3s;
	background-color: ${props => props.color || colors.white};
	outline: none;

	${({ selected }) =>
		selected &&
		`
    opacity: 1;
    width: 3.875em;
	 border-radius: 10px;
  `}
`;

export const ThumbnailContainer = styled.div`
	padding-top: 1em;
	display: flex;
	justify-content: space-between;
	max-width: 100%;
	overflow-x: auto;
	padding-right: 1em;
	position: relative;
`;

export const Thumbnail = styled.div`
	min-width: 19%;
	min-height: 7em;
	margin-right: 1em;
	cursor: pointer;
	overflow: hidden;

	@media (max-width: 900px) {
		min-width: 33%;
		min-height: 12em;
	}
`;
