import theme from 'config/theme';
import styled from 'styled-components';

const { headerHeight, mobileHeaderHeight } = theme;

const Main = styled.main`
	background-color: ${props => props.bg || '#fff'};
	padding-top: calc(${headerHeight} - 1px);
	@media (max-width: 900px) {
		padding-top: ${mobileHeaderHeight};
	}
`;

export default Main;
