import React from 'react';

import styled from 'styled-components';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { GOOGLE_PLACES_API_KEY } from 'config/keys';
import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';

const { colors, inputs } = theme;

const SearchContainer = styled.div`
	width: 100%;
	margin-bottom: 16px;
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const getCustomStyles = (isPlaceholderValue: boolean) => ({
	control: (provided: any) => ({
		...provided,
		backgroundColor: colors.white,
		color: colors.darkBlue,
		padding: '12px',
		border: 'none',
		borderRadius: inputs.borderRadius,
		boxShadow: 'none',
		height: inputs.height,
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: isPlaceholderValue ? colors.darkBlue : provided.color,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	}),
	container: (provided: any) => ({
		...provided,
		width: '100%',
		marginBottom: '16px',
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: 'black',
	}),
	menu: (provided: any) => ({
		...provided,
		backgroundColor: 'white',
		borderRadius: '16px',
		padding: '10px',
		zIndex: '1000',
	}),
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isFocused && colors.pink,
		padding: '10px',
		boxShadow: 'none',
		background: 'none',
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: colors.darkBlue,
	}),
});

interface AutoCompleteProps {
	showAllCountries?: boolean;
	placeholder?: string;
	isErrorState?: boolean;
	isPlaceholderValue?: boolean;
	errorMessage?: string;
	onChange(change: { place_id: string; description: string }): void;
}

export const AutoComplete = ({
	showAllCountries = false,
	placeholder = '',
	isPlaceholderValue = false,
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	onChange,
}: AutoCompleteProps) => {
	const handleSelect = (change: any): void => {
		if (!change) {
			return;
		}
		onChange(change.value);
	};

	return (
		<>
			<SearchContainer>
				<GooglePlacesAutocomplete
					apiKey={GOOGLE_PLACES_API_KEY}
					selectProps={{
						placeholder: placeholder,
						onChange: handleSelect,
						styles: getCustomStyles(isPlaceholderValue),
					}}
					autocompletionRequest={{
						componentRestrictions: showAllCountries ? undefined : { country: ['za'] },
						types: ['address'],
						// Alternate types: '(regions)'
					}}
				/>
			</SearchContainer>
			{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
		</>
	);
};
