import theme from 'config/theme';
import styled from 'styled-components';

const { colors, lineHeight, fonts } = theme;

const Paragraph = styled.p`
	color: ${colors.grey60};
	font-size: 14px;
	font-weight: 400;
	line-height: ${lineHeight.relaxed};
	font-family: ${fonts.Gilroy};
	margin-bottom: 20px;
	padding-right: 20px;
`;

export default Paragraph;
