import theme from 'config/theme';
import styled from 'styled-components';

const { colors } = theme;

const Container = styled.section`
	padding: 0 14vw 80px;
	background-color: ${props => props.bgColor || colors.white};

	@media (max-width: 900px) {
		padding: 60px 30px;
	}

	@media (max-width: 500px) {
		padding: 30px 20px;
	}

	@media (max-width: 450px) {
		padding: 20px !important;
	}
`;

export default Container;
