import arrowLeftImg from 'assets/images/icons/arrow-left-white.svg';
import arrowRightImg from 'assets/images/icons/arrow-right-white.svg';
import React, { Component, Fragment } from 'react';
import { Gesture } from 'react-with-gesture';

import {
	Arrow,
	CarouselContainer,
	ItemContainer,
	PositionContainer,
	RoundedPositionMarker,
	PreviewContainer,
	Thumbnail,
	ThumbnailContainer,
} from './styles';

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

class Carousel extends Component {
	static defaultProps = {
		variant: 'default',
		auto: true,
		delay: 3000,
		swipeThreshold: 60,
		minSwipeThreshold: 30,
	};

	state = {
		currentPosition: 0,
	};

	componentDidMount() {
		const { auto } = this.props;
		if (auto) {
			this.createInterval();
		}
	}

	componentWillUnmount() {
		this.clearInterval();
	}

	handleKeyUp = ({ keyCode }) => {
		const { currentPosition } = this.state;
		switch (keyCode) {
			case LEFT_ARROW:
				this.setPosition(currentPosition - 1);
				break;
			case RIGHT_ARROW:
				this.setPosition(currentPosition + 1);
				break;
			default:
				this.setPosition(currentPosition);
		}
	};

	createInterval = () => {
		this.clearInterval();
		const { delay } = this.props;

		this.interval = setInterval(() => {
			const { currentPosition } = this.state;
			this.setPosition(currentPosition + 1);
		}, delay);
	};

	clearInterval = () => {
		if (this.interval) {
			clearInterval(this.interval);
		}
	};

	setPosition = positon => {
		const { auto, children = [] } = this.props;
		if (!children || children.length <= 1) {
			return;
		}
		const newPosition = positon < 0 ? children.length - 1 : positon % children.length;
		if (auto) {
			this.clearInterval();
			this.createInterval();
		}

		this.setState({
			currentPosition: newPosition,
		});
	};

	handleGestureUp = ({ distance, direction }) => {
		const { swipeThreshold } = this.props;
		const { currentPosition } = this.state;
		const isHorizontalSwipe = Math.abs(direction[0]) > Math.abs(direction[1]);

		if (distance > swipeThreshold && isHorizontalSwipe) {
			const change = direction[0] > 0 ? -1 : 1;
			this.setPosition(currentPosition + change);
		}
	};

	render() {
		const {
			children = [],
			positionMarkerColor,
			variant,
			style,
			containerStyle,
			minSwipeThreshold,
			...otherProps
		} = this.props;
		const { currentPosition } = this.state;

		return (
			<CarouselContainer onKeyUp={this.handleKeyUp} style={containerStyle} {...otherProps}>
				<PreviewContainer variant={variant} style={style}>
					<Gesture onDown={this.clearInterval} onUp={this.handleGestureUp}>
						{({ down, delta }) =>
							children &&
							children.map((child, i) => {
								const itemPosition = (i - currentPosition) % children.length;
								const aboveMinSwipeThreshold = Math.abs(delta[0]) > minSwipeThreshold;

								return (
									<ItemContainer
										key={child.key}
										style={
											down && aboveMinSwipeThreshold
												? {
														cursor: 'grabbing',
														transition: 'transform 30ms',
														transform: `translateX(calc(${itemPosition * 100}% + ${delta[0]}px))`,
												  }
												: {
														transform: `translateX(${itemPosition * 100}%)`,
												  }
										}
									>
										{child}
									</ItemContainer>
								);
							})
						}
					</Gesture>

					{variant === 'default' && (
						<PositionContainer>
							{children.map((child, i) => (
								<RoundedPositionMarker
									color={positionMarkerColor}
									key={child.key}
									onClick={() => this.setPosition(i)}
									selected={currentPosition === i}
								/>
							))}
						</PositionContainer>
					)}
					{['side-buttons', 'thumbnail'].includes(variant) && !!children && children.length > 1 && (
						<Fragment>
							<Arrow offsetSide={'left'} onClick={() => this.setPosition(currentPosition - 1)}>
								<img src={arrowLeftImg} alt="Go left"></img>
							</Arrow>
							<Arrow offsetSide={'right'} onClick={() => this.setPosition(currentPosition + 1)}>
								<img src={arrowRightImg} alt="Go Right"></img>
							</Arrow>
						</Fragment>
					)}
				</PreviewContainer>
				{variant === 'thumbnail' && (
					<ThumbnailContainer>
						{children.map((child, i) => (
							<Thumbnail key={child.key} onClick={() => this.setPosition(i)}>
								{child}
							</Thumbnail>
						))}
					</ThumbnailContainer>
				)}
			</CarouselContainer>
		);
	}
}

export default Carousel;
