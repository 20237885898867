import theme from 'config/theme';
import styled from 'styled-components';

const { colors, zIndices } = theme;

export const TrustedLandlordContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: ${colors.white};
    z-index: ${zIndices.mapOverlay};
    top: 8px;
    left: 10px;
    width: 133px;
    height: 26px;
`;

export const TrustedLandlordText = styled.div`
	font-size: 12px;
	color: ${colors.darkBlue};
	font-weight: 700;
	margin-left: 4px;
`;

export const TrustedLandlordImage = styled.img`
	margin-left: 2px;
	max-width: 100%;
	max-height: 100%;
`;