import React from 'react';

import styled from 'styled-components';

import { ReactComponent as WifiIcon } from 'assets/images/icons/wifi.svg';
import { ReactComponent as ParkingIcon } from 'assets/images/icons/secure-parking.svg';
import { ReactComponent as CouchIcon } from 'assets/images/icons/couch.svg';
import { Listing, ListingFeature, FurnishedType } from 'models/listing';
import theme from 'config/theme';
import listingService from 'services/listingService';

const { colors } = theme;

interface ListingFeatureIconsProps {
	listing: Listing;
}

const getFeatureColor = (isEnabled: boolean): string => {
	if (!isEnabled) {
		return colors.grey20;
	}
	return colors.darkBlue;
};

const ListingFeatureIcons = ({ listing }: ListingFeatureIconsProps) => {
	const hasParking = listingService.hasFeature(listing, ListingFeature.Parking);
	const hasWifi = listingService.hasFeature(listing, ListingFeature.Wifi);
	const isFurnished =
		listing.furnished === FurnishedType.Fully || listing.furnished === FurnishedType.Semi;

	return (
		<FeatureIcons>
			<FeatureIconContainer>
				<WifiIcon fill={getFeatureColor(hasWifi)} />
				{hasWifi && <Tooltip>Wifi included</Tooltip>}
			</FeatureIconContainer>
			<FeatureIconContainer>
				<ParkingIcon fill={getFeatureColor(hasParking)} stroke={getFeatureColor(hasParking)} />
				{hasParking && <Tooltip pinRight>Off-street parking</Tooltip>}
			</FeatureIconContainer>
			<FeatureIconContainer>
				<CouchIcon fill={getFeatureColor(isFurnished)} />
				{isFurnished && <Tooltip pinRight>Furnished</Tooltip>}
			</FeatureIconContainer>
		</FeatureIcons>
	);
};

const FeatureIcons = styled.div`
	display: flex;
	align-items: flex-end;
`;

const Tooltip = styled.div<{ pinRight?: boolean }>`
	color: ${colors.darkBlue};
	background: ${colors.white};
	padding: 8px;
	font-size: 14px;
	border-radius: 4px;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
	visibility: hidden;
	position: absolute;
	white-space: nowrap;
	bottom: 32px;
	${({ pinRight }) => (pinRight ? 'right: 0' : '')};
`;

const FeatureIconContainer = styled.div`
	margin: 0 8px;
	bottom: 0;
	position: relative;

	&:hover ${Tooltip} {
		visibility: visible;
	}
`;

export default ListingFeatureIcons;
