import React from 'react';
import { MouseEvent } from 'react';

import styled from 'styled-components';

import { ReactComponent as Favourite } from 'assets/images/icons/heart-filled.svg';
import { ReactComponent as Unfavourite } from 'assets/images/icons/heart.svg';
import { ReactComponent as FavouriteV2 } from 'assets/images/icons/favourite_fill.svg';
import { ReactComponent as UnfavouriteV2 } from 'assets/images/icons/favourite_empty.svg';

interface FavouriteButtonProps {
	isChecked: boolean;
	isClickPropagationDisabled?: boolean;
	onClick?(): void;
	newIcon?: boolean;
}

const FavouriteButtonContainer = styled.div`
	display: inline-block;
	align-items: center;
	justify-content: center;

	svg {
		display: block;
	}
`;

const iconStyle = {
	overflow: 'visible',
};

export const FavouriteButton = ({
	isChecked = false,
	isClickPropagationDisabled = true,
	newIcon,
	onClick,
}: FavouriteButtonProps) => {
	const handleClick = (e: MouseEvent): void => {
		if (isClickPropagationDisabled) {
			e.stopPropagation();
		}

		if (!onClick) {
			return;
		}

		onClick();
	};

	if (newIcon) {
		return (
			<FavouriteButtonContainer onClick={handleClick}>
				{isChecked ? <FavouriteV2 /> : <UnfavouriteV2 />}
			</FavouriteButtonContainer>
		);
	}

	return (
		<FavouriteButtonContainer onClick={handleClick}>
			{isChecked ? <Favourite style={iconStyle} /> : <Unfavourite style={iconStyle} />}
		</FavouriteButtonContainer>
	);
};
