import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import moment from 'moment';

import api from 'api';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import theme from 'config/theme';
import { universityDistanceService } from 'services/universityDistanceService';
import numberFormatService from 'services/numberFormatService';
import listingService from 'services/listingService';
import { FavouriteButton } from 'lib/FavouriteButton';
import Carousel from './Carousel';
import { ListingVerifications } from './ListingVerifications';
import ListingFeatureIcons from './ListingFeatureIcons';
import { analyticsService, EventName } from 'services/analyticsService';
import Tag from '../../assets/images/digs-listing/tag.png';
import { TrustedLandlordContainer, TrustedLandlordImage, TrustedLandlordText } from './styles';
import urlUtils from 'utils/urlUtils';
import { PropertyType } from 'models/digs';

const { colors, boxShadow, zIndices } = theme;

const defaultWidth =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const defaultCardHeight = 200;

const ListingCardMobile = ({
	loading = false,
	hideFavourite = false,
	onClick,
	widthPx,
	fixedHeightPx,
	landlord,
	...props
}) => {
	const [favourite, setFavourite] = useState(props.favourite);
	const listing = props;

	useEffect(() => {
		setFavourite(props.favourite);
	}, [props.favourite]);

	const handleFavouriteClick = uuid => {
		if (!authContainer.state.token) {
			modalContainer.set(ModalType.Login);
			return;
		}
		if (!favourite) {
			api.favouritesV2.createFavourite(uuid);
			setFavourite(true);
			analyticsService.trackEvent(EventName.FavouriteClick, {
				digs_listing_uuid: props.uuid || '',
			});
		} else {
			api.favouritesV2.deleteFavourite(uuid);
			setFavourite(false);
		}
	};

	const getSubTypeText = ref => {
		for (const [key, val] of Object.entries(PropertyType)) {
			if (val === ref) {
				return key;
			}
		}
		return '';
	};

	const roomsCount = props.rooms ? props.rooms.length : 0;

	return (
		<Container onClick={onClick} widthPx={widthPx} heightPx={fixedHeightPx}>
			<CarouselContainer isFlexibleHeight={!!fixedHeightPx}>
				{loading ? (
					<ShimmerDiv />
				) : (
					<Carousel
						isFlexibleHeight={!!fixedHeightPx}
						images={props.photos.map(({ url }) =>
							urlUtils.appendQueryParams(
								url,
								`h=${fixedHeightPx || defaultCardHeight}&w=${widthPx ||
									defaultWidth}&fit=crop&auto=compress&auto=format`,
							),
						)}
					/>
				)}
				<ListingVerifications
					nsfas_accredited={props.nsfas_accredited}
					varsity_college_accredited={props.varsity_college_accredited}
					aie_accredited={props.aie_accredited}
				/>
			</CarouselContainer>
			{landlord?.is_trusted_landlord && (
				<TrustedLandlordContainer>
					<TrustedLandlordImage src={Tag} />
					<TrustedLandlordText>Trusted landlord</TrustedLandlordText>
				</TrustedLandlordContainer>
			)}
			{!hideFavourite && (
				<FavouriteContainer>
					<FavouriteButton
						isChecked={favourite}
						onClick={() => handleFavouriteClick(props.uuid)}
						newIcon
					/>
				</FavouriteContainer>
			)}
			<ContentContainer>
				<Top>
					<TopInfo>
						<TopInfoItemSet>
							<div>
								<SmallText>{listingService.getLocationText(props.location)}</SmallText>
								<SmallText>
									{universityDistanceService.getUniversityDistanceText(props.university_distances)}
								</SmallText>
							</div>
						</TopInfoItemSet>
					</TopInfo>
				</Top>
				<RoomKey>
					<>
						{getSubTypeText(props.sub_type)}
						{props.lease_type === 'multi'
							? ` • ${roomsCount} room type${roomsCount > 1 ? 's' : ''} available`
							: ` • ${roomsCount} bedroom`}
					</>
				</RoomKey>
				<ListingTitle>{props.title}</ListingTitle>
				{props.availability_date &&
					(moment(props.availability_date).isBefore(moment(), 'day') ? (
						<BoldAvailableText>
							Available now
						</BoldAvailableText>
					) : (
						<AvailableText>
							Available {moment(props.availability_date).format('MMMM YYYY')}
						</AvailableText>
					))}
				{props.display_price_from && <SmallText>{'From '}</SmallText>}
				<Bottom>
					<PriceBlock>
						<Price>
							{numberFormatService.formatCurrency(
								props.price,
								listingService.getCurrencySymbol(props.currency),
							)}
						</Price>
						<PerMonthText>/{listingService.getBillingCyclePeriod(props)}</PerMonthText>
					</PriceBlock>
					<FeatureContentContainer>
						<ListingFeatureIcons listing={listing} />
					</FeatureContentContainer>
				</Bottom>
			</ContentContainer>
		</Container>
	);
};

export default ListingCardMobile;

const SmallText = styled.div`
	font-size: 12px;
	line-height: 1.3;
	margin-bottom: 0;
	color: ${colors.grey60};
	${({ smallMargin }) => (smallMargin ? 'margin-bottom: 2px;' : '')};
`;

const PerMonthText = styled.div`
	font-size: 14px;
	color: ${colors.darkBlue};
	font-weight: 400;
	margin-left: 5px;
`;

const AvailableText = styled.div`
	font-size: 12px;
	color: ${colors.darkBlue};
	font-weight: 400;
	margin-bottom: 5px;
`;

const BoldAvailableText = styled(AvailableText)`
	color: ${colors.pink};
	font-weight: 600;
`;

const Container = styled.div`
	border-radius: 16px;
	min-height: 200px;
	background-color: white;
	margin: 16px;
	margin-bottom: 24px;
	overflow: hidden;
	box-shadow: ${boxShadow.small};
	display: flex;
	flex-direction: column;
	cursor: pointer;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	${({ widthPx }) => (widthPx ? `width: ${widthPx}px; min-width: ${widthPx}px;` : '')}
	${({ heightPx }) => (heightPx ? `height: ${heightPx}px;` : '')}
`;

const CarouselContainer = styled.div`
	height: 200px;
	display: block;
	position: relative;
	z-index: 1;
	${({ isFlexibleHeight }) =>
		isFlexibleHeight
			? `
      height: auto;
      overflow: hidden;
    `
			: ''}
`;

const ContentContainer = styled.div`
	flex-grow: 1;
	position: relative;
	padding: 16px;
	display: flex;
	flex-direction: column;
	min-height: fit-content;
`;

const ListingTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
	line-height: 1.2em;
	margin: 0;
	margin-bottom: 16px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const RoomKey = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: ${colors.pink};
	margin-bottom: 16px;
`;

const Price = styled.div`
	font-size: 16px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`;

const TopInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const TopInfoItemSet = styled.div`
	display: flex;
	align-items: center;
`;

const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ShimmerDiv = styled.div`
	background-color: gray;
	height: 100%;
	width: 100%;
	animation: shimmer 4s infinite;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}
`;

const FavouriteContainer = styled.div`
	position: absolute;
	z-index: ${zIndices.mapOverlay};
	top: 16px;
	right: 16px;
`;

const PriceBlock = styled.div`
	display: flex;
	align-items: center;
`;

const FeatureContentContainer = styled.div`
	margin-top: -4px;
	display: flex;
	flex-direction: column;
`;
