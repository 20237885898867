import theme from 'config/theme';
import { createGlobalStyle } from 'styled-components';

const { colors, zIndices } = theme;

const Progress = createGlobalStyle`
        /* Make clicks pass-through */
        #nprogress {
        pointer-events: none;
        }
        #nprogress .bar {
        background: ${colors.pink};
        position: fixed;
        z-index: ${zIndices.modal};
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        }
        /* Fancy blur effect */
        #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px ${colors.pink}, 0 0 5px ${colors.pink};
        opacity: 1.0;
        transform: rotate(3deg) translate(0px, -4px);
        }
        /* Remove these to get rid of the spinner */
        #nprogress .spinner {
        display: block;
        position: fixed;
        z-index: ${zIndices.modal};
        top: 15px;
        right: 15px;
        }
        #nprogress .spinner-icon {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border: solid 2px transparent;
        border-top-color: ${colors.pink};
        border-left-color: ${colors.pink};
        border-radius: 50%;
        animation: nprogress-spinner 400ms linear infinite;
        }
        .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
        }
        .nprogress-custom-parent #nprogress .spinner,
        .nprogress-custom-parent #nprogress .bar {
        position: absolute;
        }
        @-webkit-keyframes nprogress-spinner {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
        }
        @keyframes nprogress-spinner {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }
    `;

export default Progress;
