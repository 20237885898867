import { RentalPeriod } from 'components/add-digs/types';
import { Currency, FurnishedType } from './listing';

export enum RoomType {
	Unknown = '',
	Private = 'private',
	Shared = 'shared',
}

export interface Property {
	uuid?: string;
	sub_type: string | null;
	lease_type: string | null;
	available_private_bedrooms: number;
	available_shared_bedrooms: number;
	available_room_types: number;
	total_bedrooms: number;
	rooms: Room[];
	furnished: FurnishedType | null;
	availability_date: string | null;
	move_in_date_flexible: boolean;
	lease_term: number;
	lease_term_flexible: boolean;
	total_shared_bathrooms: number;
	tenants_per_shared_bathroom: number;
	property_size: number | null;
	current_occupants: number;
	total_places_available_value: number;
	total_occupants: number;
	current_genders: TenantGender | null;
	preferred_genders: TenantGender | null;
	tenant_preferences: TenantType[];
	location_data: any | null;
	location?: Address;
	photos: Photo[];
	videos: { url: string }[];
	title: string;
	description: string;
	price: number;
	billing_cycle: RentalPeriod;
	features: Feature[];
	currency: Currency;
	is_searchable: boolean;
	digs_search_listing_uuid: string;
	complete?: boolean;
	status: Status;
}

export interface Address {
	coords: {
		latitude: number;
		longitude: number;
	};
	city: string;
	suburb: string;
	formatted_address: string;
}

export enum TenantGender {
	MaleOnly = 'male',
	FemaleOnly = 'female',
	All = 'all',
}

export interface Status {
	errors: Error[];
	status: string;
}

export interface Error {
	error: string;
	type: string;
}

export interface Searchable {
	searchable: boolean;
	allow_searchable: boolean;
	complete: boolean;
	errors: Error[];
	share_url: string;
}

export enum TenantType {
	Undergrad = 'undergrad',
	Postgrad = 'postgrad',
	YoungProfessional = 'young_adults',
	DigitalNomad = 'nomad',
	ExchangeStudent = 'exchange',
	Expat = 'expats',
}

export interface Room {
	uuid: string;
	title: string;
	photos: Photo[];
	price: number;
	availability_date: string;
	still_available?: number;
	total_available: number;
	bathroom_type: string;
	bedroom_type: RoomType;
	furnished: string;
	active: boolean;
	guests: number;
	features: Feature[];
}

export interface SimpleRoom {
	uuid: string;
	title: string;
	bathroom_type: string;
	furnished: string;
	active: boolean;
}

export interface CapacityRoom {
	uuid: string;
	active: boolean;
}

export interface SimpleRoom {
	uuid: string;
	title: string;
	bathroom_type: string;
	furnished: string;
	active: boolean;
}

export interface CapacityRoom {
	uuid: string;
	active: boolean;
}

export enum FeatureCategory {
	Unknown = '',
	Facilities = 'Facilities',
	Security = 'Safety and Security',
	Furniture = 'Furniture',
	Amenities = 'Amenities',
	Bills = 'Bills',
	Rules = 'Rules',
	Bathroom = 'Bathroom',
	Beds = 'Beds',
	Tags = 'Tags',
	LeaseConditions = 'Lease Conditions',
}

export interface Feature {
	id: number;
	name: string;
	category: string;
	active: boolean;
	meta: any;
	meta_description: FeatureMetaDescription[];
	favourite: boolean;
	url?: string;
}

export interface FeatureMetaDescription {
	valid: boolean;
	prompt: string;
	display: string;
	input_type: 'select' | 'string';
	options: string[];
	show_if?: ShowIf;
}

interface ShowIf {
	display: string;
	value: string;
}

export interface Photo {
	uuid: string;
	url: string;
	ordinal_position?: number;
	caption?: string;
	tag: string;
}
